.non-active-tree-node {
  width: "fit-content";
}

.node-breadcrumb {
  .btn:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
  }
}

.active-tree-node {
  @extend .non-active-tree-node;
  font-weight: bold;
  box-shadow: 0 0 0 0.25rem rgba($primary, $input-btn-focus-color-opacity) !important;
  -webkit-box-shadow: 0 0 0 0.25rem
    rgba($primary, $input-btn-focus-color-opacity) !important;
}
