// CSS for how the visualization is displayed on the board page

#boards-list-div .playing-card {
  display: inline-block;
  width: 30px;
  text-align: center;
}

.board-grid {
  display: grid;
  width: 100%;
  height: calc(100% - 10px);
  grid-template-rows: auto auto auto auto 1fr;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    "node-summary  strat-legend"
    "view-controls view-controls"
    "node-controls node-controls"
    "viz-legend viz-legend"
    "viz-content viz-content";
  column-gap: 10px;
  row-gap: 10px;
}

.board-big-grid {
  grid-area: viz-content;
}

.board-legend {
  grid-area: viz-legend;
  width: 100%;
}

.board-node-summary {
  grid-area: node-summary;
}

.board-node-controls {
  grid-area: node-controls;
}

.board-strat-legend {
  @extend .pt-1, .pt-lg-0;
  grid-area: strat-legend;
}

.board-view-controls {
  grid-area: view-controls;
}

.no-strat-message {
  place-self: center;
}

.big-grid {
  min-height: 400px;
  width: 100%;
}

.board-details {
  display: none;
  width: 0px;
}

.spinner-overlay {
  grid-area: viz-content;
  background-color: #000;
  width: 100%;
  height: 100%;
  opacity: 0.55;
  z-index: 2;
}

.board-popover-details {
  display: none;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75vw;
  height: 50vh;
  min-height: 250px;
}

.board-change-popover {
  position: absolute;
  top: 120px;
  left: 10px;
  max-width: 350px;
  width: calc(100vw - 40px);
  z-index: 5000 !important;
}

.hand-details {
  flex-grow: 1;
  width: 100%;
  .card-header {
    padding: 5px !important;
  }
  .card-body {
    padding: 5px 5px 0px !important;
  }
}

.node-breadcrumb {
  background-color: var(--bs-body-bg);
  opacity: 0.9;
  // ensure that the breadcrumb stays one line
  div {
    flex-shrink: 0;
  }
}

/* No styles yet for this node
.node-chooser {

}
*/

.node-viewer-container {
  max-height: 3rem;
  position: relative;
  z-index: 2;
  &.unfocused {
    z-index: 1;
  }
  height: 3rem;
  overflow: visible;
}

@include media-breakpoint-up(lg) {
  .big-grid {
    width: 100%;
    height: 100%;
  }

  .board-big-grid {
    height: 100%;
  }

  .board-grid {
    align-items: start;
    align-content: start;
    display: grid;
    height: calc(100% - 10px);

    // grid-template-rows: 90px 80px calc(100vh - 170px - 54px);
    grid-template-rows: 120px 74px 61px 30px 1fr;
    grid-template-columns: auto 2fr 1fr;
    grid-template-areas:
      "node-summary node-controls node-controls"
      "viz-content viz-content strat-legend"
      "viz-content viz-content view-controls"
      "viz-content viz-content viz-legend"
      "viz-content viz-content viz-details";
    column-gap: 10px;
    row-gap: 10px;
  }

  .board-details {
    grid-area: viz-details;
    display: block;
    width: 100%;
    height: calc(100% - 10px);
    min-height: 250px;
  }

  .board-popover-details {
    display: none;
  }

  .board-node-controls {
    height: 100%;
  }

  .node-viewer-container {
    display: flex;
    position: relative;
    z-index: 2;
    &.unfocused {
      z-index: 1;
    }
    height: 100%;
    max-height: none;
    overflow: clip;
  }
}
.runout-change-popover {
  z-index: 5000 !important;
}

@include media-breakpoint-up(md) {
  .board-change-popover {
    left: 15px;
  }
}
