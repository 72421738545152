.spot-grid {
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "spot-header spot-header"
    "spot-metadata spot-range"
    " spot-board spot-board";
  column-gap: 10px;
}
.spot-header {
  grid-area: spot-header;
}
.spot-metadata {
  grid-area: spot-metadata;
}
.spot-range {
  grid-area: spot-range;
}

.spot-board {
  grid-area: spot-board;
}
@include media-breakpoint-up(md) {
  .spot-grid {
    display: grid;
    grid-template-rows: auto auto 1fr;
    grid-template-columns: 1fr 1.99fr;
    grid-template-areas:
      "spot-header spot-board"
      "spot-metadata spot-board"
      "spot-range spot-board";
    column-gap: 10px;
  }
}
