.settings-card {
  &.on-values {
    .card-header {
      font-weight: bold;
    }
  }
  &.available-values {
    --bs-card-bg: hsla(0, 0%, 13%, 0.3) !important;
  }
}
