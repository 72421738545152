g.hand-grid-border {
  &:hover {
    rect {
      stroke-width: 1px;
      stroke: hsla(0, 0%, 0%, 0.7);
    }
    text {
      font-weight: bold;
    }
  }

  rect.selected {
    stroke-width: 1.25px;
    stroke: hsla(0, 0%, 0%, 1);
  }
}

svg.viz {
  text {
    font-size: 8pt;
  }
}

text.square-value {
  font-variant-numeric: tabular-nums;
}

text.strategy-legend-percentage {
  font-weight: 700;
}

g.strategy-legend-child {
  cursor: pointer;
  rect.strategy-legend-border {
    opacity: 0;
  }
  rect.strategy-legend-border-active {
    opacity: 0.2;
    stroke-width: 6px;
    fill: hsla(0, 0%, 90%, 0.8);
    stroke: hsla(0, 0%, 0%, 0.8);
  }
  &:hover {
    rect {
      stroke-width: 2px;
      stroke: hsla(0, 0%, 0%, 0.7);
    }
    rect.strategy-legend-border-active {
      opacity: 0.2;
      stroke-width: 6px;
      fill: hsla(0, 0%, 90%, 0.8);
      stroke: hsla(0, 0%, 0%, 0.8);
    }
    rect.strategy-legend-border {
      opacity: 0.2;
      fill: hsla(0, 0%, 90%, 0.8);
    }
  }
}

.hand-grid {
  text {
    color: #000;
    font-size: 0.5rem;
    font-weight: 400;
  }
  text.square-name {
    font-size: 0.6rem;
    font-weight: 300;
  }
}

svg.details {
  text {
    font-variant-numeric: tabular-nums;
  }
  text.details-cards {
    cursor: default;
  }
  text.strategy-details-label {
    font-variant-numeric: normal;
  }
}

@include media-breakpoint-up(sm) {
  .hand-grid {
    text {
      font-size: 0.6rem;
      font-weight: 500;
    }
    text.square-name {
      font-size: 0.7rem;
      font-weight: 300;
    }
  }

  svg.details {
    text {
      font-size: 0.6rem;
    }
    text.details-cards {
      cursor: default;
      font-size: 0.8rem;
    }
  }
}

@include media-breakpoint-up(md) {
  .hand-grid {
    text {
      font-size: 0.6rem;
      font-weight: 500;
    }
    text.square-name {
      font-size: 0.7rem;
      font-weight: 300;
    }
  }

  svg.details {
    text {
      font-size: 0.6rem;
      font-variant-numeric: tabular-nums;
    }
    text.details-cards {
      cursor: default;
      font-size: 0.8rem;
    }
  }
}

@include media-breakpoint-up(lg) {
  .hand-grid {
    text {
      font-size: 0.8rem;
      font-weight: 500;
    }
    text.square-name {
      font-size: 0.9rem;
      font-weight: 300;
    }
  }

  svg.details {
    text {
      font-size: 1rem;
      font-variant-numeric: tabular-nums;
    }
    text.details-cards {
      cursor: default;
      font-size: 1.3rem;
    }
  }
}
