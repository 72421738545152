//
//  piocloud.scss
//
//  PioCloud styles

// Fonts: Inter + Murecho
// https://rsms.me/inter/
// https://fonts.google.com/specimen/Murecho

@import url("https://rsms.me/inter/inter.css");
@import url("https://fonts.googleapis.com/css2?family=Murecho:wght@400;700&display=swap");

// Default variable overrides
$body-bg: rgb(52, 57, 62);
$body-color: #dcdcdc;

$btn-border-width: 0.25px;
$btn-close-color: $body-color;

$blue: #1f78b4;
// $indigo:  #6610f2;
// $purple:  #6f42c1;
// $pink:    #d63384;
// $red:     #dc3545;
$orange: #d95f02;
$yellow: #dead3b;
$green: #4a9c79;
// $teal:    #20c997;
// $cyan:    #0dcaf0;

$secondary: #636363;
$light: hsl(140, 10%, 94%);

$font-family-sans-serif: "Inter", -apple-system, system-ui, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-serif: Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace: Menlo, Monaco, Consolas, "Liberation Mono",
  "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;

$breadcrumb-divider: quote(">");
// $breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='white'/%3E%3C/svg%3E");
$form-select-indicator-color: $body-color;
$form-check-input-border: 1px solid rgba($body-color, 0.9);

$modal-content-bg: rgb(52, 57, 62);

$text-muted: rgba(255, 255, 255, 0.55);
$tooltip-max-width: 400px;

@import "~bootstrap/scss/bootstrap.scss";
@import "footer.scss";
@import "markdown.scss";

.breadcrumb {
  margin-bottom: 0px !important;
  font-size: smaller;
}

@include media-breakpoint-up(sm) {
  .breadcrumb {
    font-size: unset;
  }
}

h1 {
  @extend .pb-4;
}

h2 {
  @extend .py-2;
}

h3,
h4 {
  @extend .py-1;
}

// Pio-specific styles below
@import "board.scss";
@import "dropzone.scss";
@import "layout.scss";
@import "playing-cards.scss";
@import "node-buttons.scss";
@import "settings-card.scss";
@import "spot.scss";
@import "viz.scss";

.card {
  --bs-card-bg: hsl(0, 0%, 13%) !important;
}

a > .card {
  color: $body-color;
  &:hover {
    color: $primary;
    background-color: hsl(0, 0%, 5%) !important;
  }
}

.dashboard-section {
  --bs-bg-opacity: 0.5;
}

.dashboard-section-content {
  @extend .d-flex, .flex-wrap;
}

div.project-card {
  &:hover {
    background-color: hsl(140, 5%, 89%) !important;
  }
}

div.selection-card-normal {
  border-radius: 8px;
  border-width: 0.15rem;
  border-style: solid;
  border-color: transparent;
  padding: 0px 1.5px;

  &:hover {
    cursor: pointer;
    background-color: hsl(210, 9%, 28%);
  }
  text-align: center;
}

div.selection-card-selected {
  cursor: default;
  text-align: center;
  border-radius: 8px;
  border-width: 0.15rem;
  border-style: solid;
  padding: 0px 1.5px;
}
.link-overlay-div {
  z-index: 10000;
  background-color: #333333;
  width: 300px;
  min-height: 200px;
}
.no-wrap {
  white-space: nowrap;
}

.text-white {
  text-decoration-color: $body-color;
}

.text-assistance {
  color: $gray-500;
  font-style: italic;
}

.deal-inline {
  font-size: $small-font-size;
}
.flop-filter {
  font-size: $small-font-size;
}
.board-list {
  z-index: 3000;
  height: 300px;
  &.height-fill-vh {
    height: 80vh;
  }
  overflow-y: auto;
  white-space: pre-wrap;
}

.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.clickable {
  cursor: pointer;
}

.no-decor {
  text-decoration: none !important;
}
