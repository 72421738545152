.app-grid {
  display: grid;
  width: 100vw;
  min-height: 512px;
  grid-template-rows: 54px 1fr;
  grid-template-areas:
    "header"
    "content";
  row-gap: 10px;
  padding-bottom: 20px;
}

.app-navbar {
  grid-area: header;
}

.app-content {
  grid-area: content;
}

#content {
  width: 100%;
  height: 100%;
}

@include media-breakpoint-up(lg) {
  .app-grid {
    display: grid;
    width: 100vw;
    height: 100vh;
    min-height: 512px;
    grid-template-rows: 54px calc(100vh - 54px - 10px);
    grid-template-areas:
      "header"
      "content";
    row-gap: 10px;
  }
}
